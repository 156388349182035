@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "LamaSans-Regular";
    src: url("/fonts/LamaSans-Regular.woff") format("woff"),
      url("/fonts/LamaSans-Regular.eot") format("eot");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "LamaSans-Bold";
    src: url("/fonts/LamaSans-Bold.woff2") format("woff2"),
      url("/fonts/LamaSans-Bold.woff") format("woff"),
      url("/fonts/LamaSans-Bold.eot") format("eot");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "LamaSans-SemiBold";
    src: url("/fonts/LamaSans-SemiBold.woff") format("woff"),
      url("/fonts/LamaSans-SemiBold.eot") format("eot");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Mattone";
    src: url("/fonts/Mattone-150.woff2") format("woff2"),
      url("/fonts/Mattone-150.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
}

.textarea-placeholder {
  white-space: pre-line;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
  -ms-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"][readonly] {
  pointer-events: none;
}
.textarea-placeholder {
  white-space: pre-line;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
  -ms-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"][readonly] {
  pointer-events: none;
}
